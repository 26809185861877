import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Redirect, useHistory} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';

import {AdminMessageComponent} from '../../components/admin-message.component';
import {CommonGuestbookPostButtonComponent} from '../../components/buttons/common-guestbook-post-button.component';
import {ProfilesListLoadingComponent} from '../../components/loading/profiles/profiles-list-loading.component';
import {LoginComponent} from '../../components/login.component';
import {MultipleSelectChipComponent} from '../../components/multiple-select-chip.component';
import {EmptyListPatientsComponent} from '../../components/patients/empty-list-patients.component';
import {PatientCardComponent} from '../../components/patients/patient-card.component';
import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {usePatientsByFilters} from '../../hooks/patients/use-all-patients-by-filters';
import {useQuery} from '../../hooks/use-query';
import {useStores} from '../../hooks/use-stores';
import {PatientsFilterOption} from '../../interfaces/patients-filters.interface';
import {DEPARTMENT_IDS_URL_QUERY} from '../../utils/constants';
import {getGroupsAndOptionsForDepartmentsFilters} from '../../utils/get-groups-and-options-for-departments-filters';
import {isUserAdmin} from '../../utils/is-user-admin';
import {isUserNurse} from '../../utils/is-user-nurse';

function ProfilesListContainerBase() {
  const {t} = useTranslation();
  const {authStore, v2PatientsStore: patientsStore} = useStores();
  const {show} = useIntercom();
  const query = useQuery();
  const history = useHistory();

  const [selectedDepartments, setSelectedDepartments] = useState<PatientsFilterOption['id'][]>([]);

  const {items = [], loading} = usePatientsByFilters(selectedDepartments);
  const {items: itemsTotal = []} = usePatientsByFilters();

  const isAdmin = authStore.me && isUserAdmin(authStore.me);
  const isNurse = authStore.me && isUserNurse(authStore.me);

  useEffect(() => {
    if (isNurse) {
      patientsStore.fetchFilters();
    }
  }, [isNurse, patientsStore]);

  const handleChangeSelectedDepartments = useCallback(
    (value: PatientsFilterOption['id'][]) => {
      setSelectedDepartments(value);
      query.delete(DEPARTMENT_IDS_URL_QUERY);
      if (value.length) {
        query.append(DEPARTMENT_IDS_URL_QUERY, value.join(','));
      }
      history.replace({
        search: query.toString(),
      });
    },
    [history, query]
  );

  useEffect(() => {
    if (patientsStore.loadingFilters !== false || !patientsStore.filters) {
      return;
    }

    const departments = patientsStore.filters?.departments || [];

    const departmentsFromQuery = (query.get(DEPARTMENT_IDS_URL_QUERY) || '').split(',').filter(Boolean);

    const departmentsToSelect = departmentsFromQuery.filter(id => departments.some(item => item.id === id));
    handleChangeSelectedDepartments(departmentsToSelect);
  }, [
    patientsStore.loadingFilters,
    patientsStore.filters?.departments,
    query,
    handleChangeSelectedDepartments,
    patientsStore.filters,
  ]);

  const {departmentGroups, departmentOptions} = useMemo(() => {
    const {groups: departmentGroups, options: departmentOptions} = getGroupsAndOptionsForDepartmentsFilters(
      patientsStore.filters?.municipalities || [],
      patientsStore.filters?.institutions || [],
      patientsStore.filters?.departments || [],
      t('common-guestbook-post-institution-not-found-institution.group')
    );
    return {departmentGroups, departmentOptions};
  }, [
    patientsStore.filters?.departments,
    patientsStore.filters?.institutions,
    patientsStore.filters?.municipalities,
    t,
  ]);

  // if (loading || authStore.loading !== false) {
  //   return <ProfilesListLoadingComponent />;
  // }

  if (!authStore.me) {
    return <LoginComponent />;
  }

  if (itemsTotal.length === 1 && !isAdmin) {
    return <Redirect to={`${MY_PATIENTS_ROUTE.path}/${items[0].id}`} />;
  }

  return (
    <>
      {isAdmin && <AdminMessageComponent sx={{mb: 4}} />}
      {(itemsTotal.length > 1 || isAdmin) && <CommonGuestbookPostButtonComponent admin={isAdmin} />}
      {isNurse && (patientsStore.filters?.departments.length || 0) > 1 && (
        <MultipleSelectChipComponent
          label={t('common-guestbook-post-department.select')}
          groups={departmentGroups}
          options={departmentOptions}
          formControlProps={{
            sx: {
              m: 1,
              minWidth: 250,
            },
          }}
          value={selectedDepartments}
          onChange={handleChangeSelectedDepartments}
        />
      )}
      <Box
        id="profiles-list"
        sx={theme => ({
          padding: '40px 0',
          [theme.breakpoints.up('md')]: {
            padding: '50px',
          },
          [theme.breakpoints.up('lg')]: {
            padding: '60px',
          },
        })}
      >
        {!loading && authStore.loading === false ? (
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={3}>
            {items.length ? (
              items.map(item => (
                <Grid key={item.id} item xs={12} sm={4} md={3} lg={2}>
                  <PatientCardComponent {...item} />
                </Grid>
              ))
            ) : (
              <EmptyListPatientsComponent onClick={show} />
            )}
          </Grid>
        ) : (
          <ProfilesListLoadingComponent />
        )}
      </Box>
    </>
  );
}

export const ProfilesListContainer = observer(ProfilesListContainerBase);
